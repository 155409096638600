import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { UserOutlined } from '@ant-design/icons';
import { AutoComplete, Input } from 'antd';
import { DownloadOutlined } from '@ant-design/icons';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import "bootstrap-icons/font/bootstrap-icons.css";
import ReactHTMLTableToExcel from 'react-html-table-to-excel';
import { Button, PageHeader, Tag, Typography } from 'antd';
import sectionLogo from'../Images/fa fa-file-export.png';
const { Paragraph } = Typography;


const content = (
  <>
    <Paragraph>
      Nota: elige como deseas agrupar los registros a exportar: Por ESTUDIANTE o CURSO.
    </Paragraph>
  </>
);

const Content = ({ children, extraContent }) => (
    <>
    <div
      style={{
        flex: 1,
      }}
    >
      {children}
    </div>
    <div className="image">{extraContent}</div>
    </>
);

const renderTitle = (title, cant) => (
    <span>
      {title}
      <a
        style={{
          float: 'right',
        }}
      >
        {cant}
      </a>
    </span>
  );
  
  const renderItem = (title, count) => ({
    value: title,
    label: (
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
        }}
      >
        {title}
        <span>
          <UserOutlined /> {count}
        </span>
      </div>
    ),
  });

  
const options = [
    {
      label: renderTitle('Profesor', '0'),
      options: [],
    },
    {
      label: renderTitle('Materia', '0'),
      options: [],
    },
    {
      label: renderTitle('Curso', '0'),
      options: [],
    },
    {
      label: renderTitle('Estudiante', '0'),
      options: [],
    },
];

const filtrarPorPeriodo = (asistencias, periodo) => {
  //Filtrar por periodo

  if (periodo == '1er periodo') {
    asistencias = asistencias.filter((asistencia) => {
      const startDate = ['04', '02', new Date().getFullYear()];
      const endDate = ['08', '04', new Date().getFullYear()];
      const checkDate = asistencia.date.split('-');
      
      var from = new Date(startDate[2], parseInt(startDate[1])-1, startDate[0]);  // -1 because months are from 0 to 11
      var to   = new Date(endDate[2], parseInt(endDate[1])-1, endDate[0]);
      var check = new Date(checkDate[0], parseInt(checkDate[1])-1, checkDate[2]);

      if (check > from && check < to)
        return true;
    })
  }

  if (periodo == '2do periodo') {
    asistencias = asistencias.filter((asistencia) => {
      const startDate = ['09', '04', new Date().getFullYear()];
      const endDate = ['17', '06', new Date().getFullYear()];
      const checkDate = asistencia.date.split('-');
      
      var from = new Date(startDate[2], parseInt(startDate[1])-1, startDate[0]);  // -1 because months are from 0 to 11
      var to   = new Date(endDate[2], parseInt(endDate[1])-1, endDate[0]);
      var check = new Date(checkDate[0], parseInt(checkDate[1])-1, checkDate[2]);

      if (check > from && check < to)
        return true;
    })
  }

  if (periodo == '3er periodo') {
    asistencias = asistencias.filter((asistencia) => {
      const startDate = ['18', '06', new Date().getFullYear()];
      const endDate = ['16', '09', new Date().getFullYear()];
      const checkDate = asistencia.date.split('-');
      
      var from = new Date(startDate[2], parseInt(startDate[1])-1, startDate[0]);  // -1 because months are from 0 to 11
      var to   = new Date(endDate[2], parseInt(endDate[1])-1, endDate[0]);
      var check = new Date(checkDate[0], parseInt(checkDate[1])-1, checkDate[2]);

      if (check > from && check < to)
        return true;
    })
  }

  if (periodo == '4to periodo') {
    asistencias = asistencias.filter((asistencia) => {
      const startDate = ['17', '09', new Date().getFullYear()];
      const endDate = ['25', '11', new Date().getFullYear()];
      const checkDate = asistencia.date.split('-');
      
      var from = new Date(startDate[2], parseInt(startDate[1])-1, startDate[0]);  // -1 because months are from 0 to 11
      var to   = new Date(endDate[2], parseInt(endDate[1])-1, endDate[0]);
      var check = new Date(checkDate[0], parseInt(checkDate[1])-1, checkDate[2]);

      if (check > from && check < to)
        return true;
    })
  }
  return asistencias;
}

export class Exportar extends Component {
  constructor(props) {
    super(props);
    this.state = ({
        isLoaded: false,
        error: null,
        options: options,
        agrupacionSelected: false,
        secondFilter: false,
        filename: '',
        sheetname: '',
        columns: [],
        rows: [],
        periodo: '',
    });

    this.fetchProfile = this.fetchProfile.bind(this);
    this.fetchStudents = this.fetchStudents.bind(this);
    this.handleSearch = this.handleSearch.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleExportClick = this.handleExportClick.bind(this);
  }

  handleExportClick = () => {
    // if (!this.state.agrupacionSelected || !this.state.agrupacionValue)
    //   return;

    if (this.state.students.find(ele => ele.student.toLowerCase() == this.state.agrupacionValue.toLowerCase()) != undefined) {
      const curso = this.state.students.find(ele => ele.student.toLowerCase() == this.state.agrupacionValue.toLowerCase()).curso;
      let asistencias = this.state.asistencias.filter(ele => ele.curso.toLowerCase() == curso.toLowerCase());

      //Filtra que no sean duplicados
      asistencias = asistencias.filter((tag, index, array) => array.findIndex(t => t.curso == tag.curso && t.date == tag.date && t.inasistencia == tag.inasistencia
        && t.materia == tag.materia && t.periodo == tag.periodo && t.profesor == tag.profesor && t.time == tag.time
        ) == index
      );

      //Filtrar por periodo
      asistencias = filtrarPorPeriodo(asistencias, this.state.periodo);


      let classStudents = [];
      classStudents.push(this.state.allStudents.find(ele => ele.student.toLowerCase() == this.state.agrupacionValue.toLowerCase()));

      
      classStudents.map((fila) => {
        let fallas = 0;
        let key;
        let dias = [];
        let fallasxdia = 0;

        asistencias.map((row) => {

          row.inasistencia.split(',').map((estudiante => {
            if (estudiante == fila.student) {
              fallas++;
            }
          }))

          if (!dias.includes(row.date)) {
            if (row.inasistencia.split(',').includes(fila.student)) {
              fallasxdia++;
            }

            dias.push(row.date);
          }
        })

        key = Object.keys(classStudents).find(key => classStudents[key].student === fila.student);
        classStudents[key].fallas = 0;
        classStudents[key].porcentaje = 0;
        classStudents[key].dias = 0;
        if (fallas != 0) {
          classStudents[key].fallas = fallas;
          classStudents[key].porcentaje = (fallasxdia / dias.length) * 100;
          classStudents[key].dias = fallasxdia;
        }

      })

      this.setState({
        columns: asistencias,
        rows: classStudents
      });
    }

    if (this.state.teachers.find(ele => ele.toLowerCase() == this.state.agrupacionValue.toLowerCase()) != undefined) {
      if (this.state.courses.find(ele => ele.toLowerCase() == this.state.agrupacionSecondValue.toLowerCase()) != undefined) {
        let asistencias = this.state.asistencias.filter(ele => ele.curso.toLowerCase() == this.state.agrupacionSecondValue.toLowerCase() && ele.profesor.toLowerCase() == this.state.agrupacionValue.toLowerCase());

        asistencias = asistencias.filter((tag, index, array) => array.findIndex(t => t.curso == tag.curso && t.date == tag.date && t.inasistencia == tag.inasistencia
          && t.materia == tag.materia && t.periodo == tag.periodo && t.profesor == tag.profesor && t.time == tag.time
          ) == index
        );

        //Filtrar por periodo
        asistencias = filtrarPorPeriodo(asistencias, this.state.periodo);
  
        let classStudents = this.state.allStudents.filter(ele => ele.curso.toLowerCase() == this.state.agrupacionSecondValue.toLowerCase());
        classStudents.map((fila) => {
          let fallas = 0;
          let key;
          let dias = [];
          let fallasxdia = 0;
  
          asistencias.map((row) => {
  
            row.inasistencia.split(',').map((estudiante => {
              if (estudiante == fila.student) {
                fallas++;
              }
            }))
  
            if (!dias.includes(row.date)) {
              if (row.inasistencia.split(',').includes(fila.student)) {
                fallasxdia++;
              }
  
              dias.push(row.date);
            }
          })
  
          key = Object.keys(classStudents).find(key => classStudents[key].student === fila.student);
          classStudents[key].fallas = 0;
          classStudents[key].porcentaje = 0;
          classStudents[key].dias = 0;
          if (fallas != 0) {
            classStudents[key].fallas = fallas;
            classStudents[key].porcentaje = (fallasxdia / dias.length) * 100;
            classStudents[key].dias = fallasxdia;
          }
        })

        this.setState({
          columns: asistencias,
          rows: classStudents
        });

      }
    }

    if (this.state.subjects.find(ele => ele.toLowerCase() == this.state.agrupacionValue.toLowerCase()) != undefined) {
      if (this.state.courses.find(ele => ele.toLowerCase() == this.state.agrupacionSecondValue.toLowerCase()) != undefined) {
        let asistencias = this.state.asistencias.filter(ele => ele.curso.toLowerCase() == this.state.agrupacionSecondValue.toLowerCase() && ele.materia.toLowerCase() == this.state.agrupacionValue.toLowerCase());

        asistencias = asistencias.filter((tag, index, array) => array.findIndex(t => t.curso == tag.curso && t.date == tag.date && t.inasistencia == tag.inasistencia
          && t.materia == tag.materia && t.periodo == tag.periodo && t.profesor == tag.profesor && t.time == tag.time
          ) == index
        );

        //Filtrar por periodo
        asistencias = filtrarPorPeriodo(asistencias, this.state.periodo);
  
        let classStudents = this.state.allStudents.filter(ele => ele.curso.toLowerCase() == this.state.agrupacionSecondValue.toLowerCase());
        classStudents.map((fila) => {
          let fallas = 0;
          let key;
          let dias = [];
          let fallasxdia = 0;
  
          asistencias.map((row) => {
  
            row.inasistencia.split(',').map((estudiante => {
              if (estudiante == fila.student) {
                fallas++;
              }
            }))
  
            if (!dias.includes(row.date)) {
              if (row.inasistencia.split(',').includes(fila.student)) {
                fallasxdia++;
              }
  
              dias.push(row.date);
            }
          })
  
          key = Object.keys(classStudents).find(key => classStudents[key].student === fila.student);
          classStudents[key].fallas = 0;
          classStudents[key].porcentaje = 0;
          classStudents[key].dias = 0;
          if (fallas != 0) {
            classStudents[key].fallas = fallas;
            classStudents[key].porcentaje = (fallasxdia / dias.length) * 100;
            classStudents[key].dias = fallasxdia;
          }
        })

        this.setState({
          columns: asistencias,
          rows: classStudents
        });

      }
    }

    if (this.state.courses.find(ele => ele.toLowerCase() == this.state.agrupacionValue.toLowerCase()) != undefined) {
      let asistencias = this.state.asistencias.filter(ele => ele.curso.toLowerCase() == this.state.agrupacionValue.toLowerCase());
      asistencias = asistencias.filter((tag, index, array) => array.findIndex(t => t.curso == tag.curso && t.date == tag.date && t.inasistencia == tag.inasistencia
        && t.materia == tag.materia && t.periodo == tag.periodo && t.profesor == tag.profesor && t.time == tag.time
        ) == index
      );

      //Filtrar por periodo
      asistencias = filtrarPorPeriodo(asistencias, this.state.periodo);

      let classStudents = this.state.allStudents.filter(ele => ele.curso.toLowerCase() == this.state.agrupacionValue.toLowerCase());
      classStudents.map((fila) => {
        let fallas = 0;
        let key;
        let dias = [];
        let fallasxdia = 0;

        asistencias.map((row) => {

          row.inasistencia.split(',').map((estudiante => {
            if (estudiante == fila.student) {
              fallas++;
            }
          }))

          if (!dias.includes(row.date)) {
            if (row.inasistencia.split(',').includes(fila.student)) {
              fallasxdia++;
            }

            dias.push(row.date);
          }
        })

        key = Object.keys(classStudents).find(key => classStudents[key].student === fila.student);
        classStudents[key].fallas = 0;
        classStudents[key].porcentaje = 0;
        classStudents[key].dias = 0;
        if (fallas != 0) {
          classStudents[key].fallas = fallas;
          classStudents[key].porcentaje = (fallasxdia / dias.length) * 100;
          classStudents[key].dias = fallasxdia;
        }

      })

      this.setState({
        columns: asistencias,
        rows: classStudents
      });
    }

    setTimeout(() => {
      document.getElementById('test-table-xls-button').click();
    }, 100);
  }

  handleChange = (value) => {
    if (this.state.students && this.state.teachers && this.state.subjects && this.state.courses) {
      
      // if (this.state.students.find(ele => ele.student.toLowerCase() == value.toLowerCase()) == undefined && this.state.teachers.find(ele => ele.toLowerCase() == value.toLowerCase()) == undefined
      // && this.state.subjects.find(ele => ele.toLowerCase() == value.toLowerCase()) == undefined && this.state.courses.find(ele => ele.toLowerCase() == value.toLowerCase()) == undefined) {
      //   this.setState({
      //     agrupacionSelected: false
      //   })
      //   return;
      // }

      if (this.state.students.find(ele => ele.student.toLowerCase() == value.toLowerCase()) || this.state.courses.find(ele => ele.toLowerCase() == value.toLowerCase())) {
        this.setState({
          agrupacionSelected: true,
          agrupacionValue: value,
          secondFilter: false,
        })
        return;
      }

      if (this.state.teachers.find(ele => ele.toLowerCase() == value.toLowerCase()) || this.state.subjects.find(ele => ele.toLowerCase() == value.toLowerCase())) {
        this.setState({
          agrupacionValue: value,
          secondFilter: true,
        })
        return;
      }
      
      this.setState({
          agrupacionSelected: false,
          agrupacionValue: value,
          secondFilter: false,
      })

      
    }
  }

  handleSecondChange = (value) => {
    if (this.state.courses && this.state.secondFilter) {

      if (this.state.courses.find(ele => ele.toLowerCase() == value.toLowerCase())) {
        this.setState({
          agrupacionSelected: true,
          agrupacionSecondValue: value,
        })
        return;
      }
      
      this.setState({
          agrupacionSelected: false,
          agrupacionSecondValue: ''
      })

      
    }
  }

  handleSearch = (value) => {
    let res = [];

    if (!value || value.indexOf('@') >= 0) {
      this.state.options = null;
    } else {
      
      let teachers = this.state.teachers.filter(row => row.toLowerCase().includes(value.toLowerCase()));
      teachers.sort((a, b) => a.localeCompare(b));

      let subjects = this.state.subjects.filter(row => row.toLowerCase().includes(value.toLowerCase()));
      subjects.sort((a, b) => a.localeCompare(b));

      let courses = this.state.courses.filter(row => row.toLowerCase().includes(value.toLowerCase()));
      courses.sort((a, b) => a.split('°')[0]-b.split('°')[0]);

      let students = this.state.students.filter(row => row.student.toLowerCase().includes(value.toLowerCase()));
      students.sort(({student: a}, {student: b}) => a.localeCompare(b));
      //   students.sort(({curso: a}, {curso: b}) => a.split('°')[0]-b.split('°')[0]);

      let options = [
        {
          label: renderTitle('Profesor', teachers.length),
          options: [],
        },
        {
          label: renderTitle('Materia', subjects.length),
          options: [],
        },
        {
          label: renderTitle('Cursos', courses.length),
          options: [],
        },
        {
          label: renderTitle('Estudiante', students.length),
          options: [],
        },
      ];

  
      teachers.map((row) => {
        options[0].options.push(renderItem(row));
      });
      subjects.map((row) => {
        options[1].options.push(renderItem(row));
      });
      courses.map((row) => {
          options[2].options.push(renderItem(row));
      });
      students.map((row) => {
          options[3].options.push(renderItem(row.student, row.curso));
      });

      this.setState({
        options: options
      });
    }
  };

  handleSecondSearch = (value) => {
    let res = [];

    if (!value || value.indexOf('@') >= 0) {
      this.state.options = null;
    } else {

      let courses = this.state.courses.filter(row => row.toLowerCase().includes(value.toLowerCase()));
      courses.sort((a, b) => a.split('°')[0]-b.split('°')[0]);

      let options = [
        {
          label: renderTitle('Cursos', courses.length),
          options: [],
        }
      ];


      courses.map((row) => {
          options[0].options.push(renderItem(row));
      });

      this.setState({
        secondOptions: options
      });
    }
  };

  fetchStudents() {
    let url = 'getStudents.php';
    if (window.location.href.includes("localhost")) {
      url = 'http://localhost/'+url;
    }
    fetch(url)
    .then(res => res.json())
    .then((result) => {
        let cursos = result.map((row, key) => row.curso);
        cursos = ([...new Set(cursos)]);
        this.setState({
            isLoaded: true,
            allStudents: result,
        });
    },
    (error) => {
        this.setState({
            isLoaded: true,
            error
        });
    }
    );
  }

  fetchProfile() {
    let url = "getAsistencias.php";
    if (window.location.href.includes("localhost")) {
      url = "http://localhost/" + url;
    }
    fetch(url)
      .then((res) => res.json())
      .then(
        (result) => {
          let teachers = [];
          let subjects = [];
          let students = [];
          let courses = [];
          result.map((row, key) => {
            if (!teachers.includes(row.profesor.toUpperCase())) {
                teachers.push(row.profesor);
            }
            if (!subjects.includes(row.materia)) {
                subjects.push(row.materia);
            }
            if (!courses.includes(row.curso)) {
              courses.push(row.curso);
            }
            row.inasistencia.split(",").map(ele => {
                if (students.find(stu => stu.student == ele) == undefined && ele.trim() != '') {
                    students.push({student: ele, curso: row.curso});
                }
            });
          });

          students.sort(({student: a}, {student: b}) => a.localeCompare(b));
          teachers.sort((a, b) => a.localeCompare(b));
          subjects.sort((a, b) => a.localeCompare(b));
          courses.sort((a, b) => a.split('°')[0]-b.split('°')[0]);
          let options = {...this.state.options};
  
          options[0].label = renderTitle('Profesor', teachers.length);
          options[1].label = renderTitle('Materia', subjects.length);
          options[2].label = renderTitle('Cursos', students.length);
          options[3].label = renderTitle('Estudiantes', students.length);

          teachers.map((row) => {
            options[0].options.push(renderItem(row));
          });
          subjects.map((row) => {
            options[1].options.push(renderItem(row));
          });
          courses.map((row) => {
            options[2].options.push(renderItem(row));
          });
          students.map((row) => {
            options[3].options.push(renderItem(row.student, row.curso));
          });

          this.setState({
            isLoaded: true,
            asistencias: result,
            students: students,
            teachers: teachers,
            subjects: subjects,
            courses: courses,
          });
        },
        (error) => {
          this.setState({
            isLoaded: true,
            error,
          });
        }
      );
  }

  componentDidMount() {
    this.fetchProfile();
    this.fetchStudents();
  }

  render() {
    const { error, isLoaded, students } = this.state;
    if (error) {
      return (<div className="alert alert-danger" role="alert">
      Hubo un error cargando los datos <a href="#" onClick={(e) => { e.preventDefault(); window.location.reload(false); }} class="alert-link">Intentar de nuevo</a>
    </div>);
    } else if (!isLoaded) {
      return (<div className="alert alert-warning" role="alert">
      Cargando datos...
    </div>)
    } else {
        return (
            <Container style={{padding: '15px'}}>
                <PageHeader
                title="Exportar"
                className="site-page-header"
                subTitle="BETA"
                tags={<Tag color="blue">Asistencia</Tag>}
                extra={[
                  // <Button key="3">Operation</Button>,
                  // <Button key="2">Operation</Button>,
                  // <Button key="1" type="primary">
                  //   Primary
                  // </Button>,
                ]}
                avatar={{
                  src: sectionLogo,
                }}
                >
                  <Content
                  >
                    {content}
                  </Content>
                </PageHeader>
                <Row>
                    <Col >   
                        <AutoComplete
                            dropdownClassName="certain-category-search-dropdown"
                            dropdownMatchSelectWidth={this.props.dcontainer.current.offsetWidth - this.props.dcontainer.current.offsetWidth*0.4}
                            style={{
                            width: this.props.dcontainer.current.offsetWidth - this.props.dcontainer.current.offsetWidth*0.2,
                            height: '50px'
                            }}
                            options={this.state.options}
                            onSearch={this.handleSearch}
                            onChange={this.handleChange}
                        >
                            <Input.Search size="large" placeholder="Agrupación" />
                        </AutoComplete>
                    </Col>
                    <Col style={{display: (this.state.secondFilter ? 'block' : 'none')}}>   
                        <AutoComplete
                            dropdownClassName="certain-category-search-dropdown"
                            dropdownMatchSelectWidth={this.props.dcontainer.current.offsetWidth - this.props.dcontainer.current.offsetWidth*0.4}
                            style={{
                            width: this.props.dcontainer.current.offsetWidth - this.props.dcontainer.current.offsetWidth*0.2,
                            height: '50px',
                            }}
                            options={this.state.secondOptions}
                            onSearch={this.handleSecondSearch}
                            onChange={this.handleSecondChange}
                        >
                            <Input.Search size="large" placeholder="Curso" />
                        </AutoComplete>
                    </Col>
                    <Col>   
                        <AutoComplete
                            dropdownClassName="certain-category-search-dropdown"
                            dropdownMatchSelectWidth={this.props.dcontainer.current.offsetWidth - this.props.dcontainer.current.offsetWidth*0.4}
                            style={{
                            width: this.props.dcontainer.current.offsetWidth - this.props.dcontainer.current.offsetWidth*0.2,
                            height: '50px',
                            }}
                            options={[
                              {
                              label: renderTitle('Periodo'),
                              options: [renderItem('1er periodo'), renderItem('2do periodo'), renderItem('3er periodo'), renderItem('4to periodo')],
                              },
                            ]}
                            onChange={(value) => this.setState({periodo: value})}
                        >
                            <Input.Search 
                              size="large" 
                              placeholder="Periodo"
                            />
                        </AutoComplete>
                    </Col>
                    <Col lg='12'>
                      
                    <Button onClick={this.handleExportClick} disabled={!this.state.agrupacionSelected} style={{ width: this.props.dcontainer.current.offsetWidth - this.props.dcontainer.current.offsetWidth*0.2}} block type="primary" size='medium' icon={<DownloadOutlined />}>
                    Exportar
                    </Button>
                    </Col>

                    <ReactHTMLTableToExcel
                    id="test-table-xls-button"
                    table="table-to-xls"
                    filename={this.state.filename}
                    sheet={this.state.sheetname}
                    >
                    </ReactHTMLTableToExcel>

                    <table style={{display: 'none'}} id="table-to-xls">
                      <tbody>
                        <tr>
                            <th>Estudiante</th>
                            <th>Dias con Inasistencia</th>
                            <th># de Clases (Inasis.)</th>
                            {this.state.columns.map((row, key) => (
                              <th key={key}>{row.date}-{row.time} ({row.materia})</th>
                            ))}
                        </tr>
                        
                        {this.state.rows.map((row, key) => {
                          return (
                            <tr>
                                <td>{row.student}</td>

                                <td>{row.dias}</td>

                                <td>{row.fallas}</td>
                                
                                {this.state.columns.map((fila, llave) => {
                                  if (fila.curso.toLowerCase() == row.curso.toLowerCase()) {
                                    return (
                                      <td key={llave}>
                                        {(fila.inasistencia.split(',').includes(row.student.toUpperCase()) ? '❌' : '✅')}
                                      </td>
                                    )
                                  } else {
                                    return (
                                      <td key={llave}>NO APLICA</td>
                                    )
                                  }
                                })}
                            </tr>
                          )
                        })}
                      </tbody>
                    </table>
                </Row>
            </Container>
        )
    }
  }
}

export default Exportar;
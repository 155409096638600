import React, { Component, useEffect } from "react";
import PropTypes from "prop-types";
// import Table from 'react-bootstrap/Table';
import Button from "react-bootstrap/Button";
import T4ble from "react-bootstrap/Table";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import "bootstrap-icons/font/bootstrap-icons.css";
import Modal from "react-bootstrap/Modal";
import ModalDialog from "react-bootstrap/ModalDialog";
import ModalHeader from "react-bootstrap/ModalHeader";
import ModalTitle from "react-bootstrap/ModalTitle";
import ModalBody from "react-bootstrap/ModalBody";
import ModalFooter from "react-bootstrap/ModalFooter";

import { Column, Table, SortDirection, AutoSizer } from "react-virtualized";
// import ReactExport from "react-export-excel";
import "react-virtualized/styles.css";
import _, { filter } from "lodash";

import { PageHeader, Tag, Typography } from 'antd';
import sectionLogo from'../Images/fa fa-archive.png';


export class Registros extends Component {
  constructor(props) {
    super(props);

    this.state = {
      students: [],
      actualModal: {
        id: '',
        materia: '',
        date: '',
        time: '',
        curso: '',
        profesor: '',
      },
      error: null,
      isLoaded: false,
      rowsMap: [],
      curso: null,
      materia: null,
      profesor: null,
      fecha: null,
      hora: null,
      estudiante: null,
      periodo: null,
      rows: [],
      showModal: {},
      filtros: {
        Curso: {
          filtro: null,
          rows: null,
        },
        Materia: {
          filtro: null,
          rows: null,
        },
        Profesor: {
          filtro: null,
          rows: null,
        },
        Fecha: {
          filtro: null,
          rows: null,
        },
        Hora: {
          filtro: null,
          rows: null,
        },
        Estudiante: {
          filtro: null,
          rows: null,
        },
        Periodo: {
          filtro: null,
          rows: null,
        },
        Zona: {
          filtro: false,
          rows: null,
        },
        Mesa: {
          filtro: false,
          rows: null,
        },
        Verificadas: {
          filtro: false,
          rows: null,
        },
      },
    };
    this.fetchProfile = this.fetchProfile.bind(this);
    this.fetchStudents = this.fetchStudents.bind(this);
    this.handleZonaClick = this.handleZonaClick.bind(this);
    this.handleMesaClick = this.handleMesaClick.bind(this);
    this.handleVerificadasClick = this.handleVerificadasClick.bind(this);
    this.handleModalClick = this.handleModalClick.bind(this);
  }

  componentDidUpdate(prevProps, prevState) {
    let filtros = this.state.filtros;

    let rowsTemp = this.state.rowsMap;
    const filtroCurso = filtros.Curso.filtro;
    const filtroMateria = filtros.Materia.filtro;
    const filtroProfesor = filtros.Profesor.filtro;
    const filtroFecha = filtros.Fecha.filtro;
    const filtroHora = filtros.Hora.filtro;
    const filtroEstudiante = filtros.Estudiante.filtro;
    const filtroPeriodo = filtros.Periodo.filtro;

    if (prevState.filtros !== filtros) {
      if (filtroCurso && filtroCurso.trim()) {
        rowsTemp = rowsTemp.filter((row) =>
          _.startsWith(row.curso.toLowerCase(), filtroCurso.toLowerCase())
        );
      }
      if (filtroMateria && filtroMateria.trim()) {
        rowsTemp = rowsTemp.filter((row) =>
          _.includes(row.materia.toLowerCase(), filtroMateria.toLowerCase())
        );
      }
      if (filtroProfesor && filtroProfesor.trim()) {
        rowsTemp = rowsTemp.filter((row) =>
          _.includes(row.profesor.toLowerCase(), filtroProfesor.toLowerCase())
        );
      }
      if (filtroFecha && filtroFecha.trim()) {
        rowsTemp = rowsTemp.filter((row) =>
          _.startsWith(row.date.toLowerCase(), filtroFecha.toLowerCase())
        );
      }
      if (filtroHora && filtroHora.trim()) {
        rowsTemp = rowsTemp.filter((row) =>
          _.startsWith(row.time.toLowerCase(), filtroHora.toLowerCase())
        );
      }
      if (filtroEstudiante && filtroEstudiante.trim()) {
        rowsTemp = rowsTemp.filter((row) => {
          let bool = false;
          row.inasistencia.split(",").map((fila) => {
            if (!bool) {
              bool = fila
                .toLowerCase()
                .includes(filtroEstudiante.toLowerCase());
            }
          });
          return bool;
        });
      }
      if (filtroPeriodo && filtroPeriodo.trim()) {
        rowsTemp = rowsTemp.filter((row) =>
          _.startsWith(row.periodo.toLowerCase(), filtroPeriodo.toLowerCase())
        );
      }

    }

    if (prevState.filtros !== filtros) {
      this.setState({
        rows: rowsTemp,
      });
    }
  }

  handleFiltroChange = (filtro, value) => {
    let filtros = { ...this.state.filtros };
    filtros[filtro].filtro = value;
    this.setState({
      filtros: filtros,
    });
  };

  handleZonaClick() {
    let filtros = { ...this.state.filtros };
    filtros.Zona.filtro = !filtros.Zona.filtro;
    this.setState({
      filtros: filtros,
    });
  }

  handleMesaClick() {
    let filtros = { ...this.state.filtros };
    filtros.Mesa.filtro = !filtros.Mesa.filtro;
    this.setState({
      filtros: filtros,
    });
  }

  handleVerificadasClick() {
    let filtros = { ...this.state.filtros };
    filtros.Verificadas.filtro = !filtros.Verificadas.filtro;
    this.setState({
      filtros: filtros,
    });
  }

  handleModalClick = (id) => {
    if (id == undefined) {
      this.setState({
        actualModal: {},
        showTheModal: false,
      });
      return;
    }

    const row = this.state.rows.filter( row => row.id == id);

    this.setState({
      actualModal: {...row[0]},
      showTheModal: true,
    });
  };

  fetchStudents() {
    let url = "getStudents.php";
    if (window.location.href.includes("localhost")) {
      url = "http://localhost/" + url;
    }
    fetch(url)
      .then((res) => res.json())
      .then(
        (result) => {
          let cursos = result.map((row, key) => row.curso);
          cursos = [...new Set(cursos)];
          let cursoTemp = cursos.map((row, key) => <span key={key}>{row}</span>);

          let studentsTemp = result.map((row, key) => <span key={key}>{row.student.toUpperCase()}</span>);

          let filtros = {...this.state.filtros};
          filtros.Curso.rows = cursoTemp;
          filtros.Estudiante.rows = studentsTemp;
          this.setState({
            isLoaded: true,
            students: result,
            cursos: cursos,
            filtros: filtros
          });
        },
        (error) => {
          this.setState({
            isLoaded: true,
            error,
          });
        }
      );
  }

  fetchProfile() {
    let url = "getAsistencias.php";
    if (window.location.href.includes("localhost")) {
      url = "http://localhost/" + url;
    }
    fetch(url)
      .then((res) => res.json())
      .then(
        (result) => {
          // if (this.props.profesor != "DIRECTIVAS" && this.props.profesor != "KRISTI H. CASTRO") {

          // }
          // result = result.filter(row => _.startsWith(row.profesor.toLowerCase(), filtroHora.toLowerCase()));

          let cursoTemp = result.map((row, key) => row.curso);
          cursoTemp = [...new Set(cursoTemp)];
          cursoTemp = cursoTemp.map((row, key) => <span key={key}>{row}</span>);

          let materiaTemp = result.map((row, key) => row.materia);
          materiaTemp = [...new Set(materiaTemp)];
          materiaTemp = materiaTemp.map((row, key) => (
            <span key={key}>{row}</span>
          ));

          let profesorTemp = result.map((row, key) => row.profesor.toUpperCase());
          profesorTemp = [...new Set(profesorTemp)];
          profesorTemp = profesorTemp.map((row, key) => (
            <span key={key}>{row}</span>
          ));

          let fechaTemp = result.map((row, key) => row.date);
          fechaTemp = [...new Set(fechaTemp)];
          fechaTemp = fechaTemp.map((row, key) => <span key={key}>{row}</span>);

          let horaTemp = result.map((row, key) => row.time);
          horaTemp = [...new Set(horaTemp)];
          horaTemp = horaTemp.map((row, key) => <span key={key}>{row}</span>);

          let inasistencias = result.map((row, key) => row.inasistencia);
          let estudianteTemp = [];
          inasistencias.map((row, key) => {
            if (row && row.trim()) {
              row.split(",").map((fila, key) => {
                estudianteTemp.push(fila);
              });
            }
          });
          estudianteTemp = [...new Set(estudianteTemp)];
          estudianteTemp = estudianteTemp.map((row, key) => (
            <span key={key}>{row}</span>
          ));

          let periodoTemp = result.map((row, key) => row.periodo);
          periodoTemp = [...new Set(periodoTemp)];
          periodoTemp = periodoTemp.map((row, key) => (
            <span key={key}>{row}</span>
          ));

          const rowsTemp = result;

          this.setState({
            isLoaded: true,
            rows: rowsTemp,
            rowsMap: result,
            filtros: {
              Curso: {
                filtro: null,
                rows: cursoTemp,
              },
              Materia: {
                filtro: null,
                rows: materiaTemp,
              },
              Profesor: {
                filtro: null,
                rows: profesorTemp,
              },
              Fecha: {
                filtro: null,
                rows: fechaTemp,
              },
              Hora: {
                filtro: null,
                rows: horaTemp,
              },
              Estudiante: {
                filtro: null,
                rows: estudianteTemp,
              },
              Periodo: {
                filtro: null,
                rows: periodoTemp,
              },
              Zona: {
                filtro: false,
                rows: null,
              },
              Mesa: {
                filtro: false,
                rows: null,
              },
              Verificadas: {
                filtro: false,
                rows: null,
              },
            },
          });
        },
        (error) => {
          this.setState({
            isLoaded: true,
            error,
          });
        }
      );
  }

  _sortList = ({ sortBy, sortDirection }) => {
    let newList = _.sortBy(this.state.rowsMap, [sortBy]);
    if (sortDirection === SortDirection.DESC) {
      newList.reverse();
    }
    return newList;
  };

  _sort = ({ sortBy, sortDirection }) => {
    const sortedList = this._sortList({ sortBy, sortDirection });
    this.setState({ sortBy, sortDirection, sortedList });
  };

  componentDidMount() {
    this.fetchProfile();
    this.fetchStudents();
    const sortBy = "id";
    const sortDirection = SortDirection.ASC;
    const sortedList = this._sortList({ sortBy, sortDirection });
    this.setState({
      sortBy: sortBy,
      sortDirection: sortDirection,
      sortedList: sortedList,
    });
  }

  render() {
    const { error, isLoaded, rows } = this.state;
    if (error) {
      return (
        <div className="alert alert-danger" role="alert">
          Hubo un error cargando los datos{" "}
          <a
            href="#"
            onClick={(e) => {
              e.preventDefault();
              window.location.reload(false);
            }}
            class="alert-link"
          >
            Intentar de nuevo
          </a>
        </div>
      );
    } else if (!isLoaded) {
      return (
        <div className="alert alert-warning" role="alert">
          Cargando datos...
        </div>
      );
    } else {
      let totales = this.state.rows.length;
      //let iPeriodo = 0;

      // rowsTemp = rowsTemp.filter(row => row.fecha.includes(filtroFecha) || row.fecha.match(filtroFecha));
      //     rowsTemp = rowsTemp.filter(function(row) { return row.zona.trim() != ''; });

      // this.state.rowsMap.map((row, value) => {
      //     Object.keys(sin).map((key) => {
      //         if (row[key].trim() == '') {
      //             sin[key]++;
      //         }
      //     })
      //     if (row.actualizado == 'ERRONEA') {
      //         cErroneas++;
      //     }

      // });

      return (
        <div style={{padding: '15px'}} className="overflow-auto">
          <PageHeader
          title="Registros"
          className="site-page-header mb-3"
          subTitle="FULL"
          tags={<Tag color="blue">Asistencia</Tag>}
          extra={[
            // <Button key="3">Operation</Button>,
            // <Button key="2">Operation</Button>,
            // <Button key="1" type="primary">
            //   Primary
            // </Button>,
          ]}
          avatar={{
            src: sectionLogo,
          }}
          >
          </PageHeader>

          <div className="pb-4 filterContainer">
            <Container fluid>
              <Row>
                <Col xs={12} md={3} lg={3}>
                  <Container fluid>
                    <Row>
                      <Col xs={2} className="filterIcon g-0">
                        <i className="bi bi-info-circle"></i>
                      </Col>
                      <Col xs={10} className="filterInput g-0">
                        <input
                          onChange={(e) =>
                            this.handleFiltroChange("Curso", e.target.value)
                          }
                          onFocus={(e) =>
                            this.handleFiltroChange("Curso", e.target.value)
                          }
                          placeholder="Curso"
                          type="text"
                          name="curso"
                          list="list-curso"
                          autoComplete="off"
                        />
                        <div list="list-curso">
                          {this.state.filtros.Curso.rows}
                        </div>
                      </Col>
                    </Row>
                  </Container>
                </Col>
                <Col xs={12} md={3} lg={3}>
                  <Container>
                    <Row>
                      <Col xs={2} className="filterIcon g-0">
                        <i className="bi bi-journal-bookmark-fill"></i>
                      </Col>
                      <Col xs={10} className="filterInput g-0">
                        <input
                          onChange={(e) =>
                            this.handleFiltroChange("Materia", e.target.value)
                          }
                          onFocus={(e) =>
                            this.handleFiltroChange("Materia", e.target.value)
                          }
                          placeholder="Materia"
                          type="text"
                          name="materia"
                          list="list-materia"
                          autoComplete="off"
                        />
                        <div list="list-materia">
                          {this.state.filtros.Materia.rows}
                        </div>
                      </Col>
                    </Row>
                  </Container>
                </Col>
                <Col xs={12} md={3} lg={3}>
                  <Container>
                    <Row>
                      <Col xs={2} className="filterIcon g-0">
                        <i className="bi bi-person-workspace"></i>
                      </Col>
                      <Col xs={10} className="filterInput g-0">
                        <input
                          onChange={(e) =>
                            this.handleFiltroChange("Profesor", e.target.value)
                          }
                          onFocus={(e) =>
                            this.handleFiltroChange("Profesor", e.target.value)
                          }
                          placeholder="Profesor"
                          type="text"
                          name="profesor"
                          list="list-profesor"
                          autoComplete="off"
                        />
                        <div list="list-profesor">
                          {this.state.filtros.Profesor.rows}
                        </div>
                      </Col>
                    </Row>
                  </Container>
                </Col>
                <Col xs={12} md={3} lg={3}>
                  <Container>
                    <Row>
                      <Col xs={2} className="filterIcon g-0">
                        <i className="bi bi-calendar"></i>
                      </Col>
                      <Col xs={10} className="filterInput g-0">
                        <input
                          onChange={(e) =>
                            this.handleFiltroChange("Fecha", e.target.value)
                          }
                          onFocus={(e) =>
                            this.handleFiltroChange("Fecha", e.target.value)
                          }
                          placeholder="Fecha"
                          type="text"
                          name="fecha"
                          list="list-fecha"
                          autoComplete="off"
                        />
                        <div list="list-fecha">
                          {this.state.filtros.Fecha.rows}
                        </div>
                      </Col>
                    </Row>
                  </Container>
                </Col>
              </Row>
              <Row className="pt-2 justify-content-start">
                <Col xs={12} md={3} lg={3}>
                  <Container>
                    <Row>
                      <Col xs={2} className="filterIcon g-0">
                        <i className="bi bi-person-badge"></i>
                      </Col>
                      <Col xs={10} className="filterInput g-0">
                        <input
                          onChange={(e) =>
                            this.handleFiltroChange(
                              "Estudiante",
                              e.target.value
                            )
                          }
                          onFocus={(e) =>
                            this.handleFiltroChange(
                              "Estudiante",
                              e.target.value
                            )
                          }
                          placeholder="Estudiante"
                          type="text"
                          name="estudiante"
                          list="list-estudiante"
                          autoComplete="off"
                        />
                        <div list="list-estudiante">
                          {this.state.filtros.Estudiante.rows}
                        </div>
                      </Col>
                    </Row>
                  </Container>
                </Col>
                <Col xs={12} md={3} lg={3}>
                  <Container>
                    <Row>
                      <Col xs={2} className="filterIcon g-0">
                        <i className="bi bi-person-badge"></i>
                      </Col>
                      <Col xs={10} className="filterInput g-0">
                        <input
                          onChange={(e) =>
                            this.handleFiltroChange("Periodo", e.target.value)
                          }
                          onFocus={(e) =>
                            this.handleFiltroChange("Periodo", e.target.value)
                          }
                          placeholder="Periodo"
                          type="text"
                          name="periodo"
                          list="list-periodo"
                          autoComplete="off"
                        />
                        <div list="list-periodo">
                          {this.state.filtros.Periodo.rows}
                        </div>
                      </Col>
                    </Row>
                  </Container>
                </Col>
                <Col xs={12} md={3} lg={3}>
                  <Container>
                    <Row>
                      <Col xs={2} className="filterIcon g-0">
                        <i className="bi bi-clock"></i>
                      </Col>
                      <Col xs={10} className="filterInput g-0">
                        <input
                          onChange={(e) =>
                            this.handleFiltroChange("Hora", e.target.value)
                          }
                          onFocus={(e) =>
                            this.handleFiltroChange("Hora", e.target.value)
                          }
                          placeholder="Hora"
                          type="text"
                          name="hora"
                          list="list-hora"
                          autoComplete="off"
                        />
                        <div list="list-hora">
                          {this.state.filtros.Hora.rows}
                        </div>
                      </Col>
                    </Row>
                  </Container>
                </Col>
                <Col style={{paddingLeft: '0'}} xs={12} md={3} lg={3}>
                  <Container>
                    <Row>
                      <Col xs={2} sm={2} md={3} lg={2}>                      
                        <OverlayTrigger
                          placement="bottom"
                          overlay={<Tooltip>Exportar <strong>Excel</strong></Tooltip>}
                        >
                          <Button onClick={this.handleExportClick} style={{padding: '0.3rem 0.5rem', lineHeight: '1.4'}} variant="outline-secondary"><i class="bi bi-file-earmark-spreadsheet"></i></Button>
                        </OverlayTrigger>
                        </Col>
                    </Row>
                  </Container>
                </Col>
              </Row>
            </Container>
          </div>
          
          <small style={{ float: "left", marginLeft: "5px" }}>
            <b>Total de Registros: {totales}</b>
          </small>
          <Table
            width={this.props.dcontainer.current.offsetWidth - 20}
            height={600}
            headerHeight={40}
            rowHeight={40}
            rowCount={this.state.rows.length}
            rowGetter={({ index }) => this.state.rows[index]}
            onRowClick={({ rowData }) => this.handleModalClick(rowData.id)}
          >
            {/* <Column flexGrow={1} width={100} label="#" dataKey="id" /> */}
            <Column flexGrow={1} width={100} label="Curso" dataKey="curso" />
            <Column
              flexGrow={1}
              width={100}
              label="Materia"
              dataKey="materia"
            />
            <Column
              flexGrow={1}
              width={100}
              label="Profesor"
              dataKey="profesor"
            />
            <Column
              flexGrow={1}
              width={100}
              label="Inasistencia"
              dataKey="inasistencia"
            />
            <Column
              flexGrow={1}
              width={100}
              label="Periodo"
              dataKey="periodo"
            />
            <Column flexGrow={1} width={100} label="Fecha" dataKey="date" />
            <Column flexGrow={1} width={100} label="Hora" dataKey="time" />
          </Table>

          <Modal
              show={this.state.showTheModal}
              onHide={() => this.handleModalClick()}>
            <Modal.Header closeButton>
              <Modal.Title>
                <div>
                  {this.state.actualModal.materia}
                  <br />
                  {this.state.actualModal.date} - {this.state.actualModal.time}
                </div>
              </Modal.Title>
            </Modal.Header>
            <Modal.Body className="text-center">
              <T4ble
                id="tablaDatos"
                bordered
                hover
                size="sm"
                style={{ width: "100%" }}
              >
                <thead>
                  <tr>
                    <th>°</th>
                    <th>Materia</th>
                    <th>Profesor</th>
                    <th>Periodo</th>
                    <th>Fecha</th>
                    <th>Hora</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>
                      <OverlayTrigger
                        placement="bottom"
                        overlay={<Tooltip>{this.state.actualModal.curso}</Tooltip>}
                      >
                        <div>{this.state.actualModal.curso}</div>
                      </OverlayTrigger>
                    </td>
                    <td>
                      <OverlayTrigger
                        placement="bottom"
                        overlay={<Tooltip>{this.state.actualModal.materia}</Tooltip>}
                      >
                        <div>{this.state.actualModal.materia}</div>
                      </OverlayTrigger>
                    </td>
                    <td>
                      <OverlayTrigger
                        placement="bottom"
                        overlay={<Tooltip>{this.state.actualModal.profesor}</Tooltip>}
                      >
                        <div>{this.state.actualModal.profesor}</div>
                      </OverlayTrigger>
                    </td>
                    <td>
                      <OverlayTrigger
                        placement="bottom"
                        overlay={<Tooltip>{this.state.actualModal.periodo}</Tooltip>}
                      >
                        <div>{this.state.actualModal.periodo}</div>
                      </OverlayTrigger>
                    </td>
                    <td>
                      <OverlayTrigger
                        placement="bottom"
                        overlay={<Tooltip>{this.state.actualModal.date}</Tooltip>}
                      >
                        <div>{this.state.actualModal.date}</div>
                      </OverlayTrigger>
                    </td>
                    <td>
                      <OverlayTrigger
                        placement="bottom"
                        overlay={<Tooltip>{this.state.actualModal.time}</Tooltip>}
                      >
                        <div>{this.state.actualModal.time}</div>
                      </OverlayTrigger>
                    </td>
                  </tr>
                </tbody>
              </T4ble>
              <T4ble
                id="tablaDatos"
                bordered
                hover
                size="sm"
                className="m-3"
                style={{ width: "90%", tableLayout: "auto" }}
              >
                <thead>
                  <tr>
                    <th>Asistencia</th>
                    <th>Estudiante</th>
                  </tr>
                </thead>
                <tbody>
                  {this.state.students.map(
                    (key, index) =>
                      this.state.actualModal.curso == key.curso && (
                        <tr key={index}>
                          <td>
                            {(() => {
                              if (
                                this.state.actualModal.inasistencia
                                  .split(",")
                                  .includes(key.student)
                              ) {
                                return (
                                  <i className="bi bi-x-circle text-danger"></i>
                                );
                              } else {
                                return (
                                  <i className="bi bi-check2-circle text-success"></i>
                                );
                              }
                            })()}
                          </td>

                          <td>
                            <div>{key.student}</div>
                          </td>
                        </tr>
                      )
                  )}
                </tbody>
              </T4ble>
            </Modal.Body>
            <Modal.Footer>
                <Button
                  variant="secondary"
                  onClick={() => this.handleModalClick()}
                >
                  Cerrar
                </Button>
              </Modal.Footer>
          </Modal>
        </div>
      );
    }
  }
}

export default Registros;

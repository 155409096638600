import PropTypes from 'prop-types';
import React, { Component } from 'react';
import Table from 'react-bootstrap/Table';
import Button from 'react-bootstrap/Button';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import "bootstrap-icons/font/bootstrap-icons.css";
import Modal from 'react-bootstrap/Modal';
import ModalDialog from 'react-bootstrap/ModalDialog';
import ModalHeader from 'react-bootstrap/ModalHeader';
import ModalTitle from 'react-bootstrap/ModalTitle';
import ModalBody from 'react-bootstrap/ModalBody';
import ModalFooter from 'react-bootstrap/ModalFooter';

import { TimePicker } from 'antd';
import { DatePicker } from 'antd';
import moment from 'moment';
import 'antd/dist/antd.css';
import { PageHeader, Tag} from 'antd';
import sectionLogo from'../Images/fa fa-clock.png';

export class Asistencia extends Component {
    constructor(props) {
        super(props);

        this.state = {
            error: null,
            isLoaded: false,
            students: [],
            checkboxes: {},
            profesor: this.props.profesor.toUpperCase().trimEnd(),
            cursoValue: '1°-A',
            materiaValue: 'English Language Arts',
            dateValue: '',
            timeValue: '',
            confirmSendModal: false,
            insertResponse: null
        }
        this.fetchStudents = this.fetchStudents.bind(this);
        this.saveAsistencia = this.saveAsistencia.bind(this);
        this.handleCheckBoxClick = this.handleCheckBoxClick.bind(this);
        this.handleStudentsModalClick = this.handleStudentsModalClick.bind(this);
        this.handleCursoChange = this.handleCursoChange.bind(this);
        this.handleMateriaChange = this.handleMateriaChange.bind(this);
        this.handleDateChange = this.handleDateChange.bind(this);
        this.handleTimeChange = this.handleTimeChange.bind(this);
    }

    handleCheckBoxClick = (id) => {
        let checkboxes = {...this.state.checkboxes};
        if (checkboxes[id] !== undefined)
            checkboxes[id] = !checkboxes[id];
        else {
            checkboxes[id] = true;
        }
        
        this.setState({
            checkboxes: checkboxes
        });
    }

    handleDateChange(date, dateString, id) {
        this.setState({
            dateValue: dateString,
        });
    }

    handleTimeChange(time, timeString, id) {
        this.setState({
            timeValue: timeString,
        });
    }

    handleCursoChange(e) {
        this.setState({
            cursoValue: e.target.value,
            checkboxes: {}
        });
    }

    handleMateriaChange(e) {
        this.setState({
            materiaValue: e.target.value,
        });
    }

    handleStudentsModalClick() {
        this.setState({confirmSendModal: !this.state.confirmSendModal});
    }

    fetchStudents() {
        let url = 'getStudents.php';
        if (window.location.href.includes("localhost")) {
          url = 'http://localhost/'+url;
        }
        fetch(url)
        .then(res => res.json())
        .then((result) => {
            let cursos = result.map((row, key) => row.curso);
            cursos = ([...new Set(cursos)]);
            this.setState({
                isLoaded: true,
                students: result,
                cursos: cursos
            });
        },
        (error) => {
            this.setState({
                isLoaded: true,
                error
            });
        }
        );
    }

    saveAsistencia() {
        this.setState({confirmSendModal: false});
        let inasistencia = '';
        let i = 0;
        {this.state.students.map((row, key) => {
            if (row.curso == this.state.cursoValue && this.state.checkboxes[row.id]) {
                inasistencia += (i != 0 ? ',':'')+row.student;
                i++;
            }
        })}

        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json'},
            body: JSON.stringify({ date: this.state.dateValue, time: this.state.timeValue,
                curso: this.state.cursoValue,  materia: this.state.materiaValue, profesor: this.state.profesor.toUpperCase(), inasistencia: inasistencia})
        };
        let url = 'insertData.php';
        if (window.location.href.includes("localhost")) {
          url = 'http://localhost/'+url;
        }
        fetch(url, requestOptions)
            .then(response => response.json())
            .then((data) => {
                this.setState({
                    insertResponse: data,
                    checkboxes: {}
                });
            },
            (error) => {
                this.setState({
                    insertResponse: null,
                    error: error
                });
            });
    }

    componentDidMount() {
        this.fetchStudents();
    }

    render() {

        const { error, isLoaded, students } = this.state;
        if (error) {
          return (<div className="alert alert-danger" role="alert">
          Hubo un error cargando los datos <a href="#" onClick={(e) => { e.preventDefault(); window.location.reload(false); }} class="alert-link">Intentar de nuevo</a>
        </div>);
        } else if (!isLoaded) {
          return (<div className="alert alert-warning" role="alert">
          Cargando datos...
        </div>)
        } else {
            return (
                <Container style={{padding: '15px'}}>
                    
                    <PageHeader
                    title="Asistencia"
                    className="site-page-header mb-3"
                    subTitle="FULL"
                    tags={<Tag color="blue">Asistencia</Tag>}
                    extra={[
                        // <Button key="3">Operation</Button>,
                        // <Button key="2">Operation</Button>,
                        // <Button key="1" type="primary">
                        //   Primary
                        // </Button>,
                    ]}
                    avatar={{
                        src: sectionLogo,
                    }}
                    >
                    </PageHeader>
                    <Row style={{paddingRight: '25px', paddingLeft: '25px'}} className='pl-4 pr-4'>
                        <Col sm="6">
                            <div class="form-group pb-2">
                                <DatePicker style={{width: '49%', marginRight: '2%'}} onChange={this.handleDateChange}/>
                                <TimePicker defaultOpenValue={moment('00:00:00', 'HH:mm:ss')} style={{width: '49%'}} onChange={this.handleTimeChange}/>
                            </div>
                            <div class="form-group pb-2">
                                <select class="form-control" value={this.state.cursoValue} onChange={this.handleCursoChange}>
                                {this.state.cursos.map((key, value) =>
                                    <option key={value} value={key}>{key}</option>
                                )}
                                </select>
                            </div>
                            <div class="form-group">
                                <select class="form-control" id="exampleFormControlSelect1" value={this.state.materiaValue} onChange={this.handleMateriaChange}>
                                <option value='English Language Arts'>English Language Arts</option><option value='Motricidad Gruesa'>Motricidad Gruesa</option><option value='Motricidad Fina'>Motricidad Fina</option><option value='Sensopercepción'>Sensopercepción</option><option value='Integradas'>Integradas</option><option value='Matemáticas'>Matemáticas</option><option value='Lectores Competentes'>Lectores Competentes</option><option value='Castellano'>Castellano</option><option value='Danza'>Danza</option><option value='Música'>Música</option><option value='Informática'>Informática</option><option value='Física'>Física</option><option value='Química'>Química</option><option value='Biología'>Biología</option><option value='Educación Cristiana'>Educación Cristiana</option><option value='Ética'>Ética</option><option value='Sociales'>Sociales</option><option value='Filosofía'>Filosofía</option><option value='Educación Física'>Educación Física</option><option value='Arte'>Arte</option><option value='Naturales'>Naturales</option><option value='Science'>Science</option><option value='Caligrafía'>Caligrafía</option><option value='Christian Education'>Christian Education</option><option value='Geometría'>Geometría</option><option value='Competencias Ciudadanas'>Competencias Ciudadanas</option><option value='Ciencias Políticas'>Ciencias Políticas</option><option value='Inglés'>Inglés</option>
                                </select>
                            </div>

                            <Button onClick={() => this.setState({confirmSendModal: !this.state.confirmSendModal})} className="btn btn-primary btn-lg mb-4 mt-4" disabled={(!this.state.dateValue.length > 0 || !this.state.timeValue.length)}>Guardar <i className="bi bi-download"/> </Button>
                            
                            {this.state.insertResponse && this.state.insertResponse.status == 'success' &&
                                <div class="alert alert-success" role="alert">
                                    {this.state.insertResponse.message}
                                </div>  
                            }
                        </Col>
                        <Col sm="6">
                            <Table id="tablaDatos" responsive bordered hover size="sm">
                                <caption style={{captionSide: 'top'}}>
                                    <small>Selecciona SOLO los estudiantes que no hayan asistido!</small>
                                </caption>
                                <thead>
                                    <tr className="clickable" onClick={() => this.handleCheckBoxClick(-1)}>
                                    <th className="checkbox">
                                    </th>
                                    <th>Nombre</th>
                                    <th>Curso</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {this.state.students.map((row, key) =>
                                        (this.state.cursoValue == row.curso &&
                                        <tr className="clickable" onClick={() => this.handleCheckBoxClick(row.id)} key={key}>
                                        <td>
                                            <Button style={{lineHeight: '1'}} className="checkbox" onClick={() => this.handleCheckBoxClick(row.id)} variant="outline-secondary">
                                                <i className={"bi "+(this.state.checkboxes[row.id] ? "bi-x-lg text-danger" : "bi-square hidden")+" "}></i></Button>
                                        </td>
                                        <td>{row.student}</td>
                                        <td>{row.curso}</td>
                                        </tr>
                                        )
                                    )}
                                </tbody>
                            </Table>
                        </Col>
                    </Row>


                    <Modal show={this.state.confirmSendModal} onHide={this.handleStudentsModalClick}>
                        <Modal.Header closeButton>
                        <Modal.Title>Guardar asistencia</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <small>
                            <h6>Fecha</h6>
                            <p>{this.state.dateValue}</p>
                            <h6>Hora</h6>
                            <p>{this.state.timeValue}</p>
                            <h6>Curso</h6>
                            <p>{this.state.cursoValue}</p>
                            <h6>Materia</h6>
                            <p>{this.state.materiaValue}</p>
                            </small>

                            <Table id="tablaDatos" responsive bordered hover size="sm">
                                <thead>
                                    <tr>
                                    <th>Inasistencias</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {this.state.students.map((row, key) =>
                                        (row.curso == this.state.cursoValue && this.state.checkboxes[row.id] &&
                                            <tr key={key}>
                                            <td>{row.student}</td>
                                            </tr>
                                        )
                                    )}
                                </tbody>
                            </Table>
                            
                            Confirma si deseas guardar esta asistencia ⬇️
                        </Modal.Body>
                        <Modal.Footer>
                        <Button onClick={this.saveAsistencia}
                        variant="primary">Guardar <i className="bi bi-download"/></Button>
                        <Button variant="secondary" onClick={this.handleStudentsModalClick}>
                        Cerrar
                        </Button>
                        </Modal.Footer>
                    </Modal>
                </Container>
                
            )
        }
    }
}

export default Asistencia
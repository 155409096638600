import logo from './Images/logo.png';
import './App.css';
import React, {Component, useRef} from 'react';
import Button from 'react-bootstrap/Button';
import Asistencia from './components/Asistencia';
import Registros from './components/Registros';
import Exportar from './components/Exportar';
import Insertar from './components/Insertar';
import { Helmet } from 'react-helmet';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Modal from 'react-bootstrap/Modal';
import ModalDialog from 'react-bootstrap/ModalDialog';
import ModalHeader from 'react-bootstrap/ModalHeader';
import ModalTitle from 'react-bootstrap/ModalTitle';
import ModalBody from 'react-bootstrap/ModalBody';
import ModalFooter from 'react-bootstrap/ModalFooter';
import ReCAPTCHA from 'react-google-recaptcha';


export default class App extends Component {
  constructor(props) {
      super(props);
      this.state = {
        component : 'asistencia',
        profesores: [],
        selectedProfesor: '',
        password: null,
        profesorNotOnList: false,
        verifyResponse: null,
        verificado: false,
      }
      this.fetchProfesores = this.fetchProfesores.bind(this);
      this.verifyPassword = this.verifyPassword.bind(this);
      this.handleProfesorChange = this.handleProfesorChange.bind(this);
      this.recaptcha = this.recaptcha.bind(this);
      this.captcha = React.createRef(null);
      this.dcontainer = React.createRef(null);
  }
  recaptcha() {
    if (this.captcha.current.getValue()) {
      this.setState({verificado: true});
    }
  }

  datosButton = () => {
      this.setState({ component : 'asistencia' });
  }

  insertarButton = () => {
      this.setState({ component : 'insertar' });
  }
  registrosButton = () => {
    this.setState({ component : 'registros' });
}
  exportarButon = () => {
    this.setState({ component : 'exportar' });
  }

  handleProfesorChange = (event) => {
    this.setState({selectedProfesor: event.target.value});
    let found = false;
    this.state.profesores.map((row, key) => {
      if (row.profesor == event.target.value) {
        found = true;
      }
    })
    if (event.target.value && event.target.value.trim()) {
      this.setState({profesorNotOnList: !found});
    } else {
      this.setState({profesorNotOnList: found});
    }

  }

  fetchProfesores() {
    let url = 'getProfesores.php';
    if (window.location.href.includes("localhost")) {
      url = 'http://localhost/'+url;
    }
    fetch(url)
    .then(res => res.json())
    .then((result) => {
      this.setState({
          profesores: result,
      });
    },
    (error) => {
        this.setState({
            error
        });
    }
    );
  }

  verifyPassword() {
    if (this.state.selectedProfesor == null ||
      this.state.selectedProfesor == '' || this.state.password == null || this.state.password == '' || !this.state.verificado) {
        return;
    }

    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json'},
        body: JSON.stringify({ details: {profesor: this.state.selectedProfesor, contrasena: this.state.password, captcha: this.captcha.current.getValue()} })
    };
    let url = 'verifyPassword.php';
    if (window.location.href.includes("localhost")) {
      url = 'http://localhost/'+url;
    }
    fetch(url, requestOptions)
        .then(response => response.json())
        .then((data) => {
            this.setState({
                verifyResponse: data
            });
            if (data.status == "success") {
              setTimeout(function() {
                this.setState({loggedIn: true});
              }.bind(this), 1000)
            }
        },
        (error) => {
            this.setState({
                verifyResponse: false
            });
        });
}

  componentDidMount() {
    this.fetchProfesores();
  }

  render() {
    let compVisible;
    if (this.state.component == 'asistencia') {
      compVisible = <Asistencia profesor={this.state.selectedProfesor}/>
    } else if (this.state.component == 'registros') {
      compVisible = <Registros dcontainer={this.dcontainer} profesor={this.state.selectedProfesor}/>
    } else if (this.state.component == 'exportar') {
      compVisible = <Exportar dcontainer={this.dcontainer}/>
    } else {
      compVisible = <div className="alert alert-danger" role="alert">
                      Error
                    </div>
    }

    let login =
    <Modal show={true}>
      <Modal.Header>
      </Modal.Header>
      <Modal.Body style={{paddingRight: '3em', paddingLeft: '3em'}}>
      <Modal.Title style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}>FCCES Utilities</Modal.Title>
        <div className='text-center pt-3'>
          <a href='https://fcces.org'>
            <img style={{height: '4rem', width: 'auto'}} className='logo' src={logo} alt='Logo'/>
          </a>
        </div>
        <div className="form-group pb-2 pt-2">
            <label className="pb-2">Staff</label>
            <input value={this.state.selectedProfesor} autoFocus className={'form-control' + (this.state.verifyResponse && this.state.verifyResponse.status == 'success' ? 'is-valid' : '')} 
            onChange={this.handleProfesorChange} onFocus={this.handleProfesorChange} placeholder="Nombre" name="nombre" list="list-nombre" autoComplete='off'/>
            <div list="list-nombre">
                {this.state.profesores.map((row, key) => 
                <span key={key}>{row.profesor}</span>)
                }
            </div>
            {/* {this.state.profesorNotOnList ?
              <small className='text-danger'>Por favor selecciona uno de la lista</small>
              :
              ""
            } */}
        </div>
        <div className="form-group pb-4">
            <label className="pb-2">Contraseña</label>
            <input onChange={(e) => this.setState({password: e.target.value})} type="password" className={'form-control'+
            (this.state.verifyResponse && this.state.verifyResponse.status == 'error' ? ' is-invalid' : '')+(this.state.verifyResponse && this.state.verifyResponse.status == 'success' ? ' is-valid' : '')} placeholder="Contraseña" />
        </div>

        <div className="recaptcha text-center">
            <ReCAPTCHA style={{display: 'inline-block'}} ref={this.captcha} sitekey={window.location.href.includes('localhost') ? '6LdaXcUeAAAAAHpJE91wZPDf5kq-OQ669oiM2ih6' : '6LcyVcUeAAAAAOdFEEvV83PzunA6FNKM_xkrhyTF'} onChange={this.recaptcha} />
        </div>
        <button onClick={this.verifyPassword} style={{width: '100%'}} className="btn btn-primary btn-block mt-2 mb-4" disabled={(this.state.selectedProfesor == null ||
          this.state.selectedProfesor == '' || this.state.password == null || this.state.password == '' || !this.state.verificado)}>Acceder</button>

        {/* {this.state.verifyResponse != null && !this.state.loggedIn ?
          <div style={{padding: '0.4rem 0.4rem'}} className="alert alert-danger mt-4" role="alert">
          {this.state.verifyResponse.message}
          </div>
          : ""
        } */}
        
      </Modal.Body>
      <Modal.Footer>
        {/* <Container fluid className={'App'}>
          <Row className='g-0'>
            <Col sm="6" lg="6" className='align-left'>
            <a href="https://fcces.org" target="_blank"><small>Main page</small></a>
            </Col>
            <Col sm="6" lg="6" className='align-left'>
              <a href="mailto:agonzalezrubio@fcces.org"><small>Necesitas ayuda?</small></a>
            </Col>
          </Row>
        </Container> */}
      </Modal.Footer>
    </Modal>

    return (
      <Container fluid className={'App'}>
        { this.state.loggedIn ?
          <Row className='g-0'>
            <Col md="2" className='D-sidebar'>
              <div className='top'>
                <a href='https://fcces.org'>
                  <img className='logo' src={logo} alt='Logo'/>
                </a>
                <p className='siteTitle'>FCCES Utilities</p>
              </div>

              <div className='bot'>
                <div className='pestañas'>
                  <ul>
                    <li className={this.state.component == 'asistencia' ? 'active':'nonactive'}>
                    <a onClick={this.datosButton}><span className={this.state.component == 'asistencia' ? 'active-span':''}></span> Asistencia</a>
                    </li>
                    <li className={this.state.component == 'registros' ? 'active':'nonactive'}>
                    <a onClick={this.registrosButton} className="nuxt-link-active"><span className={this.state.component == 'registros' ? 'active-span':''}></span> Registros</a>
                    </li>
                    <li className={this.state.component == 'exportar' ? 'active':'nonactive'}>
                    <a onClick={this.exportarButon}><span className={this.state.component == 'grupos' ? 'active-span':''}></span> Exportar</a>
                    </li>
                    <li className={this.state.component == 'insertar' ? 'active':'nonactive'}>
                    <a href="https://fcces.org/afa.php?token=eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9" target="_blank" onClick={this.insertarButton}><span className={this.state.component == 'insertar' ? 'active-span':''}></span> AFA</a>
                    </li>
                  </ul>
                </div>
              </div>
              {/* <Button variant='primary' onClick={this.dataButton}>Login</Button> */}
            </Col>
            <Col md="9" ref={this.dcontainer} className='D-container'>
              { this.state.loggedIn &&
              compVisible 
              }
            </Col>
          </Row>
        
        :
        
        <div className="behind-modal">{login}</div>
        
        }
      </Container>
      
    );
  }
 
}

